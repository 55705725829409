import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ScrollableBox from '@activebrands/core-web/components/ScrollableBox';
import { getRecentlyViewedItems } from '@activebrands/core-web/state/recentlyViewed/actions';
import ProductCardMini from 'components/product/ProductCardMini';
import { styled } from 'styletron-react';

const ItemWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto',
    height: '100%',
    position: 'relative',
    padding: '16px 12px 12px 12px',
});

const RecentlyViewed = () => {
    const [recentlyViewedProducts, setRecentlyViewedPrducts] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            const result = await dispatch(getRecentlyViewedItems());
            setRecentlyViewedPrducts(result);
        };

        fetchData();
    }, []);

    return (
        <ItemWrapper>
            <ScrollableBox withScrollbar $style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                {recentlyViewedProducts?.map(product => (
                    <ProductCardMini
                        $style={{ marginBottom: '8px' }}
                        key={product.name}
                        type="addToBasket"
                        {...product}
                        media={product.media[0] ?? {}}
                    />
                ))}
            </ScrollableBox>
        </ItemWrapper>
    );
};

RecentlyViewed.propTypes = {
    recentlyViewedProducts: PropTypes.object,
};

export default RecentlyViewed;
